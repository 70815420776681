// staging
// export const backendFirebaseConfig = {
//     apiKey: "AIzaSyDkX3C_MW2MViu9A5Hkyef9FfKdxSKfoxY",
//     authDomain: "app.firecms.co",
//     projectId: "firecms-dev-2da42",
//     storageBucket: "firecms-dev-2da42.appspot.com",
//     messagingSenderId: "619891749797",
//     appId: "1:619891749797:web:8eb7a62b0d0252bdeb2e4c"
// };

// production
export const backendFirebaseConfig = {
    apiKey: "AIzaSyDa9HY0_H0GfAwvQnE3Zgo_ZJJEVO6CgrQ",
    authDomain: "app.firecms.co",
    projectId: "firecms-backend",
    storageBucket: "firecms-backend.appspot.com",
    messagingSenderId: "175047346381",
    appId: "1:175047346381:web:2ad1401d6db12303318184"
};
